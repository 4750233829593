import { useMemo } from 'react'
import { useAppSelector } from '../hooks'

import { ExperimentPage } from '@types'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import experiments from 'experiments'
import { useRouter } from 'next/router'
import { getCurrentFlowIndex, getNextFlowIndex } from 'experiments/utils'
import { ParsedUrlQueryInput } from 'querystring'

type ExperimentState = {
  id: string | null
}

// Define the initial state.
const initialState: ExperimentState = {
  id: null,
}

export const experimentSlice = createSlice({
  name: 'experiment',
  initialState,
  reducers: {
    setExperimentId: (state, action) => {
      state.id = action.payload
    },
  },
})

const selectExperiment = (state: RootState): ExperimentState => state.experiment

const selectExperimentId = (state: RootState): string | null =>
  selectExperiment(state)?.id

const { setExperimentId } = experimentSlice.actions

const experimentReducer = experimentSlice.reducer

const useExperiment = () => {
  const experimentId = useAppSelector(selectExperimentId)
  const router = useRouter()

  const experiment = useMemo(
    () =>
      experiments.find(experiment => experiment.id === experimentId) ||
      experiments.find(e => e.default),
    [experimentId],
  )

  const goNextRouteFlow = (
    currentPage: ExperimentPage,
    params?: NodeJS.Dict<string | null | ParsedUrlQueryInput | undefined>,
  ) => {
    return router.push({
      pathname: '/',
      query: {
        ...router.query,
        ...params,
        flow: getNextFlowIndex(currentPage, experiment),
      },
    })
  }

  interface INextStepFlowProps {
    currentPage?: ExperimentPage
    isLastStep?: boolean
    nextStep?: number | null
    lastPageUrl?: string
    params?: NodeJS.Dict<string | null | ParsedUrlQueryInput | undefined>
  }

  const goNextStepFlow = ({
    currentPage,
    isLastStep = false,
    nextStep = 1,
    lastPageUrl = '/quiz/processing',
    params = {},
  }: INextStepFlowProps) => {
    let flow = router.query.flow
    if (currentPage) {
      const currentIndex = getCurrentFlowIndex(currentPage, experiment)
      if (currentIndex !== -1) {
        flow = currentIndex.toString()
      }
    }

    return router.push({
      pathname: isLastStep ? lastPageUrl : '/',
      query: {
        ...router.query,
        ...params,
        step: nextStep,
        flow,
      },
    })
  }

  return { experiment, goNextRouteFlow, goNextStepFlow }
}

export {
  experimentReducer,
  selectExperiment,
  selectExperimentId,
  setExperimentId,
  useExperiment,
}
