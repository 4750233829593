/* eslint-disable */
import { useRouter } from 'next/router'
import React from 'react'
import { useExperiment } from 'redux/experiment'

export const trackMixpanelEvent = (
  event_name: string,
  funnel?: string,
  props?: Record<any, any>,
) => {

  try {
    if ((window as any).mixpanel) {
      ;(window as any).mixpanel.track(event_name, {
        ...props,
        funnel: funnel || '8am-pt1',
      })
      console.log('MPF', event_name)
    }
  } catch (e) {
    console.log(e)
  }
}

export const MixpanelTracker = function () {
  const router = useRouter()
  const { experiment } = useExperiment()

  const handleRouteChange = (url: any) => {
    // Send track event on every page view.
    trackMixpanelEvent('Page view', experiment?.funnel, { url })
  }

  React.useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.asPath, router.events])
  return null
}
