import {
  convertHeightFromMetricToImperial,
  convertHeightFromImperialToMetric,
} from '@utils/convert-units'
import { useState, useEffect, useRef } from 'react'

interface HeightProps {
  value: number | null
  onChange: (height: number | null) => void
}

const Height: React.FunctionComponent<HeightProps> = ({ value, onChange }) => {
  const { inches, feet } = convertHeightFromMetricToImperial(value)
  const [feetValue, setFeetValue] = useState(feet)
  const [inchesValue, setInchesValue] = useState(inches)
  const feetInput = useRef<HTMLInputElement>(null)
  const inchesInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setFeetValue(feet)
    setInchesValue(inches)
  }, [feet, inches, setFeetValue, setInchesValue])

  const feetChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const newValue = parseInt(e.target.value)
    setFeetValue(newValue)
    onChange(convertHeightFromImperialToMetric(newValue, inchesValue))
  }

  const inchesChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const newValue = parseInt(e.target.value)
    setInchesValue(newValue)

    if (e.target.value !== '') {
      onChange(convertHeightFromImperialToMetric(feetValue, newValue))
    }
  }

  return (
    <div className="flex space-x-10 mb-6 items-center w-full">
      <div className="quiz-text-input-container">
        <input
          className="quiz-input text-center"
          required
          value={feetValue || ''}
          onChange={feetChangeHandler}
          type="number"
          min="3"
          max="8"
          placeholder="Feet"
          inputMode="numeric"
          ref={feetInput}
        />
        <span>ft.</span>
      </div>
      <div className="quiz-text-input-container">
        <input
          className="quiz-input text-center"
          required
          onChange={inchesChangeHandler}
          value={inchesValue?.toFixed(0) || ''}
          type="number"
          min="0"
          max="11"
          placeholder="Inches"
          inputMode="numeric"
          ref={inchesInput}
        />{' '}
        <span>in.</span>
      </div>
    </div>
  )
}

export default Height
