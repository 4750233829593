import React, { useEffect, useMemo } from 'react'
import { StepComponentProps, SliderQuizStep } from '@types'
import Actions from '@components/quiz/actions'
import InputRange, { Range } from 'react-input-range'
import { useAppSelector } from 'redux/hooks'
import { selectCurrentWeight, selectMaxWeightLoss } from 'redux/user'

type Props = StepComponentProps<SliderQuizStep, number | Range>

export default function Slider(props: Props): React.ReactElement<Props> {
  const { value, step, onChange } = props
  const nextEnabled = useMemo(() => !!value, [value])
  const [maxValue, setMaxValue] = React.useState(100)
  const maxValueStored = useAppSelector(selectMaxWeightLoss)
  const currentWeight = useAppSelector(state =>
    selectCurrentWeight(state, 'imperial'),
  )

  useEffect(() => {
    if (maxValueStored) {
      setMaxValue(maxValueStored)
    }
  }, [maxValueStored])

  const handleInputChange = (value: number | Range) => {
    handleNext(value)
  }

  const handleNext = (newValue: number | Range) => {
    onChange(step.id, newValue)
  }

  return (
    <>
      <div className="quiz-slider-input-range">
        <InputRange
          maxValue={maxValue}
          minValue={10}
          step={5}
          value={value || 10}
          formatLabel={value => {
            return `${value}${value >= 300 ? '+' : ''} lbs`
          }}
          onChange={handleInputChange}
        />
      </div>
      {currentWeight && Number(value) > 0 && (
        <div className="max-w-sm mx-auto mt-40 md:mt-0">
          <div className="quiz-paragraph mx-auto text-center mt-6">
            You&apos;ll weigh {currentWeight - Number(value)} lbs after losing{' '}
            {value as number} lbs.
          </div>
        </div>
      )}
      <Actions
        onNext={() => handleNext(value)}
        nextEnabled={nextEnabled}
        previousStep={props.previousStep}
        nextStep={props.nextStep}
        isLastStep={props.isLastStep}
      />
    </>
  )
}

// import React, { useMemo } from 'react'
// import { StepComponentProps, SliderQuizStep } from '@types'
// import Actions from '@components/quiz/actions'
// import InputRange, { Range } from 'react-input-range'
// import { useAppSelector } from 'redux/hooks'
// import { selectCurrentWeight } from 'redux/user'
// import { selectHeight } from 'redux/quiz'
// type Props = StepComponentProps<SliderQuizStep, number | Range>

// function calculateHealthyWeightRangeMetric(
//   heightMeters: number,
//   weightKg: number,
// ) {
//   // Calculate current BMI
//   // Define healthy BMI range
//   const minHealthyBMI = 18.5
//   const maxHealthyBMI = 24.9

//   // Calculate healthy weight range in kg
//   const minHealthyWeightKg = minHealthyBMI * (heightMeters * heightMeters)
//   const maxHealthyWeightKg = maxHealthyBMI * (heightMeters * heightMeters)

//   // Calculate weight loss range in pounds (1 kilogram = 2.20462 pounds)
//   const minWeightLoss = weightKg - maxHealthyWeightKg
//   const maxWeightLoss = weightKg - minHealthyWeightKg

//   return {
//     minWeightLossPounds: Math.round(minWeightLoss * 2.20462),
//     maxWeightLossPounds: Math.round(maxWeightLoss * 2.20462),
//   }
// }

// export default function Slider(props: Props): React.ReactElement<Props> {
//   const { value, step, onChange } = props
//   const nextEnabled = useMemo(() => !!value, [value])
//   const weight = useAppSelector(state => selectCurrentWeight(state, 'metric'))
//   const currentWeight = useAppSelector(state =>
//     selectCurrentWeight(state, 'imperial'),
//   )
//   const height = useAppSelector(state => selectHeight(state, 'metric'))

//   if (!weight || !height) {
//     return <></>
//   }

//   const { minWeightLossPounds, maxWeightLossPounds } =
//     calculateHealthyWeightRangeMetric(height / 100, weight)

//   const minValue = (currentWeight || 0) - maxWeightLossPounds
//   const maxValue = (currentWeight || 0) - minWeightLossPounds

//   const handleInputChange = (value: number | Range) => {
//     handleNext(value)
//   }

//   const handleNext = (newValue: number | Range) => {
//     onChange(step.id, newValue)
//   }

//   return (
//     <>
//       <div className="quiz-slider-input-range">
//         <InputRange
//           maxValue={maxValue}
//           minValue={minValue}
//           step={5}
//           value={value}
//           formatLabel={value => {
//             return `${value}${value >= 300 ? '+' : ''} lbs`
//           }}
//           onChange={handleInputChange}
//         />
//       </div>
//       {currentWeight && Number(value) > 0 && (
//         <div className="max-w-sm mx-auto">
//           <div className="quiz-paragraph mx-auto text-center mt-6">
//             For your height, a{' '}
//             <a
//               href="https://www.cdc.gov/healthyweight/assessing/index.html#:~:text=If%20your%20BMI%20is%20less,falls%20within%20the%20obese%20range."
//               target="_blank"
//               rel="noreferrer"
//               className="underline"
//             >
//               healthy weight range
//             </a>{' '}
//             is between {minValue} lbs and {maxValue} lbs. You would be{' '}
//             {currentWeight - Number(value)} lbs after losing {value as number}{' '}
//             lbs.
//           </div>
//         </div>
//       )}
//       <Actions
//         onNext={() => handleNext(value)}
//         nextEnabled={nextEnabled}
//         previousStep={props.previousStep}
//         nextStep={props.nextStep}
//         isLastStep={props.isLastStep}
//       />
//     </>
//   )
// }
