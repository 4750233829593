import React from 'react'

export const goodQuestions = [
  {
    title: 'How does Measured work?',
    content: (
      <div>
        <div className="mb-2">
          Measured is a comprehensive and personalized approach to weight loss.
          We help you achieve your weight loss and health goals through
          prescription medications, behavior coaching, and customized meal
          guides.
        </div>
        <div className="mb-2">
          Start by taking a short quiz to ensure you&#39;re eligible for our
          program. You&#39;ll then complete a full medical assessment that will
          gather more detailed information on your medical history, lifestyle,
          and health goals. If you haven&#39;t recently, you&#39;ll also be
          asked to complete a metabolic assessment.
        </div>
        <div className="mb-2">
          Your Care Team (which includes an obesity-trained clinician and
          licensed health coach) will then review your answers and create a
          personalized treatment plan that&#39;s designed to achieve your weight
          loss goals. Your treatment plan includes:
        </div>
        <ul role="list">
          <li>Prescription medications, if applicable</li>
          <li>
            Unlimited messaging access to both your clinician and health coach,
            as well as a team of dedicated Care Coordinators
          </li>
          <li>Personalized meal guide</li>
          <li>Educational content</li>
          <li>Access to a community of 10,000+ others</li>
        </ul>
        <div className="mb-2">
          Measured is there for you every step of the way. Your Care Team is
          available to answer your questions and refine your treatment plan over
          time.
        </div>
      </div>
    ),
  },
  {
    title: 'How much weight can I lose?',
    content: (
      <>
        <div className="mb-2">
          While every person is different, the average Measured member sees at
          least a 5% weight reduction in the first 3 months and an up to 10%
          reduction by month 6.
        </div>
        <div className="mb-2">
          Medications that Measured clinicians can prescribe have been
          scientifically proven to help patients{' '}
          <a
            href="https://www.nejm.org/doi/full/10.1056/NEJMoa2032183"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 underline"
          >
            lose 15% of their body weight in 68 weeks
          </a>
          .
        </div>
      </>
    ),
  },
  {
    title: 'How much does Measured cost?',
    content: (
      <>
        <div className="mb-2">
          Signing up for Measured today costs $39. This fee includes your
          metabolic assessment, initial assessment, and online consultation.
          From that point forward, Measured charges $78/month for ongoing and
          unlimited messaging access with your Care Team.
        </div>
        <div className="mb-2">
          These costs do <b>not</b> include any prescribed medications.
          Measured&#39;s team of insurance-experts will help you with Prior
          Authorization requests and get you the most cost-savings possible
          through your local pharmacy. With insurance coverage, medications
          should cost $30 or less per month.
        </div>
      </>
    ),
  },
  {
    title: 'Does Measured take insurance?',
    content: (
      <>
        <div className="mb-2">
          Measured does not accept insurance for its initial consultation ($39)
          or monthly membership fees.
        </div>
        <div className="mb-2">
          However, your Measured Care Team will work directly with your
          insurance to handle the prior authorization process and ensure you get
          the most cost-savings possible on any prescribed medications.
        </div>
        <div className="mb-2">
          Please note: we are <b>NOT</b> able to accept patients onto our
          platform who have Medicaid, Medicare or Tricare insurance.
        </div>
      </>
    ),
  },
  {
    title: 'What is prior authorization?',
    content: (
      <>
        <div className="mb-2">
          Prior authorization is when insurance requires patients to obtain
          pre-approval of a service or medication before care is provided. This
          allows the plan to evaluate whether care is medically necessary and
          otherwise covered.
        </div>
        <div className="mb-2">
          Many insurance plans require prior authorization for GLP-1
          medications, such as Ozempic, Mounjaro, Wegovy, and Saxenda.
        </div>
        <div className="mb-2">
          Measured has a best-in-class Prior Authorization team that will work
          directly with your insurance company to help you receive approval for
          any medications you are prescribed by your Measured clinician.
        </div>
      </>
    ),
  },
  {
    title: 'How do labs work?',
    content: (
      <div>
        <div className="mb-2">
          Measured requires lab results containing Creatinine, eGRF, and HbA1c
          from the last 12 months in order to prescribe certain medications.
        </div>
        <ul role="list">
          <li>
            <b>If you have completed</b> the necessary lab work, you can upload
            your results into the telehealth portal to be viewed by your Care
            Team.
          </li>
          <li>
            <b>If you need to complete</b> the necessary lab work, we will send
            you a lab requisition that you can take to your local Quest
            Diagnostics or Labcorp.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: 'What medications does Measured prescribe?',
    content: (
      <>
        <div className="mb-2">
          If appropriate, your Measured clinician will prescribe prescription
          medications that have been studied in clinical trials and proven to be
          safe with at least a 5% weight loss effect.
        </div>
        <div>GLP-1 Medications Include:</div>
        <ul role="list" className="mb-2">
          <li>Wegovy</li>
          <li>Saxenda</li>
          <li>Mounjaro*</li>
          <li>Ozempic*</li>
          <li>Rybelsus*</li>
        </ul>
        <div>Oral Medications Include</div>
        <ul role="list" className="mb-2">
          <li>Bupropion</li>
          <li>Naltrexone</li>
          <li>Metformin</li>
          <li>Topiramate</li>
        </ul>
        <div>*Requires an existing diabetes diagnosis</div>
      </>
    ),
  },
]
