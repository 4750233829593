import Logo from '../../public/m-wordmark.svg'
import Link from 'next/link'
import Image from 'next/image'

export default function Footer() {
  return (
    <footer className="pt-10">
      <div className="flex items-center justify-center">
        <Logo />
      </div>
      <nav className="quiz-heading-lg center flex flex-col gap-3 py-8 antialiased">
        <Link href="https://trymeasured.com/terms">Terms of Use</Link>
        <Link href="https://trymeasured.com/privacy">Privacy</Link>
        <Link href="https://trymeasured.com/hipaa-policy">
          HIPAA Compliance
        </Link>
      </nav>
      <div className="text-center">
        <div className="quiz-heading-2xl">Contact:</div>
        <p className="quiz-paragraph center font-medium hover:underline">
          <a href="mailto:careteam@trymeasured.com">careteam@trymeasured.com</a>
        </p>
      </div>
      <div className="text-center mt-10">
        <div className="quiz-heading-2xl">Address:</div>
        <p className="quiz-paragraph center font-medium">245 8th, Avenue</p>
        <p className="quiz-paragraph center font-medium">New York, NY 10011</p>
      </div>
      <div className="mt-10 text-center">
        <Image
          className="mx-auto"
          src="/legal-badge.png"
          alt="Measured Seal"
          width={118}
          height={101}
        />
      </div>
      <p className="text-secondary1 text-center text-[10px] mt-10 leading-[15px]">
        Measured Health affiliated medical practices are independently owned and
        operated by licensed physicians who provide services using the Measured
        Health&apos;s telehealth platform.
      </p>
      <p className="text-secondary1 text-sm text-center border-t border-t-secondary1/20 mt-5 pt-5">
        &copy; 2022 Measured. All Rights Reserved.
      </p>
    </footer>
  )
}
