import { MapSelectOptions } from '@components/SelectOption'
import { StepComponentProps, TextQuizStep, Track } from '@types'
import { useEffect } from 'react'
import { useAppDispatch } from 'redux/hooks'
import { setStepValue } from 'redux/quiz'
import { setTrack } from 'redux/user'

type Props = StepComponentProps<TextQuizStep, string>

const LabResults: React.FC<Props> = ({ onChange, step, value }: Props) => {
  const dispatch = useAppDispatch()
  const handleInputChange = (value: string) => {
    dispatch(setStepValue({ stepId: step.id, value }))

    if (!value) {
      dispatch(setTrack(Track.BEHAVIORAL))
    } else {
      handleNext(value)
    }
  }
  useEffect(() => {
    dispatch(setTrack(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNext = (newValue: string) => {
    onChange(step.id, newValue)
  }

  const options = [
    {
      value: true,
      content: 'Yes',
      selected: value == 'Yes',
      onClick: handleInputChange,
    },
    {
      value: false,
      content: 'No',
      selected: value == 'No',
      onClick: handleInputChange,
    },
  ]

  return (
    <div className="max-w-2xl">
      <span className="quiz-paragraph left callout-text">
        We use <b>blood Lab results</b> to look at multiple{' '}
        <b>metabolic factors</b> to help determine eligibility for prescription
        weight-loss medication.
        <br />
        <br />
        Lab Options:
        <ul>
          <li>
            <b>Upload Lab Results</b> (free) - send us lab results from the past
            12 months. You&#39;ll need: Creatinine, eGFR and Hemoglobin A1C.
          </li>
          <li>
            <b>At-Home Test Kit</b> ($49) - we&#39;ll ship you a home testing
            kit or you can visit a local Quest Labs location
          </li>
        </ul>
        <br />
        Labs are separate from the membership fees. We&#39;ve found many health
        plans fully or reimburse lab fees, and they are often an HSA eligible
        expense. Please check your unique plan(s) for details.
      </span>
      <div className="py-8">
        <h2 className="quiz-heading-2xl mb-8">Are you ok with this?</h2>
        <div className="flex flex-wrap min-w-full max-w-2xl items-center justify-center gap-2.5">
          {MapSelectOptions({
            width: 'w-full',
            options,
          })}
        </div>
      </div>
    </div>
  )
}

export default LabResults
