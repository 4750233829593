import { Experiment } from '@types'
import survey from '../survey'

const r1: Experiment = {
  ...survey,
  id: 'r1',
  funnel: 'FOX',
  default: false,
}

export default r1
