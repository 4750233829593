import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { QuizStepType, StepId } from '@types'
import { RootState } from 'redux/store'

// Define a type for the slice state
type QuizFormState = Record<string, any>
type QuizAnswers = Record<string, any>

// Define the initial state using that type
const initialState: QuizFormState = {}

export const formState = createSlice({
  name: 'quizFormState',
  initialState,
  reducers: {
    setStepValue: (
      state,
      action: PayloadAction<{ stepId: StepId; value: any }>,
    ) => {
      state[action.payload.stepId] = action.payload.value
    },
    setQuizAnswers: (state, action: PayloadAction<QuizAnswers>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    reset: () => initialState,
  },
})

export const { setStepValue, setQuizAnswers, reset } = formState.actions

export const selectStepValue = (state: RootState, stepId: StepId): any => {
  return (state.quiz.formState as QuizFormState)[stepId]
}

export const selectGender = (state: RootState): string | null => {
  return (state.quiz.formState as QuizFormState)[StepId.GENDER]
}

export const selectDateOfBirth = (state: RootState): string => {
  const birthday = state.quiz.formState[QuizStepType.BIRTHDAY]
  if (!birthday) {
    return ''
  }
  const date = new Date(birthday.year, birthday.month, birthday.day)
  return date.toISOString().slice(0, 10).replace(/-/g, '')
}

export const selectState = (state: RootState): string | null => {
  return (state.quiz.formState as QuizFormState)[StepId.STATE]
}

export const selectZipCode = (state: RootState): string | null => {
  return (state.quiz.formState as QuizFormState)[StepId.ZIPCODE]
}

export default formState.reducer
