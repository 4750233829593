import { useState, useMemo, useEffect } from 'react'
import { HeightWeightQuizStep, StepComponentProps } from '@types'
import Actions from '@components/quiz/actions'
import Height from './height'
import Weight from './weight'

type Props = StepComponentProps<
  HeightWeightQuizStep,
  { height: number | null; weight: number | null }
>

export default function HeightWeight(props: Props): React.ReactElement<Props> {
  const { value, step, onChange } = props
  const [height, setHeight] = useState<number | null>(null)
  const [weight, setWeight] = useState<number | null>(null)

  const nextEnabled = useMemo(
    () =>
      (step.showHeight ? !!height : true) &&
      (step.showWeight ? !!weight : true),
    [step, height, weight],
  )

  useEffect(() => {
    setHeight(value.height)
    setWeight(value.weight)
  }, [value.height, value.weight])

  const nextHandler = () => {
    onChange(step.id, { height, weight })
  }

  const heightChangeHandler = (newValue: number | null) => {
    setHeight(newValue)
    // onChange(step.id, { height: newValue, weight })
  }

  const weightChangeHandler = (newValue: number | null) => {
    setWeight(newValue)
    // onChange(step.id, { height, weight: newValue })
  }

  return (
    <>
      <div className="my-5 text-xl flex flex-col items-center max-w-sm mx-auto">
        {step.showHeight && (
          <Height onChange={heightChangeHandler} value={height} />
        )}
        {step.showWeight && (
          <Weight onChange={weightChangeHandler} value={weight} />
        )}
      </div>
      <Actions
        onNext={nextHandler}
        nextEnabled={nextEnabled}
        previousStep={props.previousStep}
        nextStep={props.nextStep}
        isLastStep={props.isLastStep}
      />
    </>
  )
}
