import ContinueButton from '@components/ContinueButton'

export default function ReadyToSeeResults({
  onClick,
}: {
  onClick: () => void
}) {
  return (
    <div className="text-center py-4 space-y-4">
      <h1 className="quiz-heading-3xl">Ready To See Results?</h1>
      <ContinueButton
        text="Get My Plan"
        extraClasses="dark-button"
        enabled={true}
        onClick={onClick}
      />
    </div>
  )
}
