import {
  VictoryChart,
  VictoryArea,
  VictoryAxis,
  VictoryTheme,
  VictoryLabel,
  VictoryScatter,
} from 'victory'

const currentMonth = new Date().getMonth()
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const getFullMonth = (i: number) => {
  const newIndex = currentMonth + i
  const index =
    newIndex < monthNames.length ? newIndex : newIndex - monthNames.length
  return monthNames[index].toUpperCase()
}

const getMonth = (i: number) => getFullMonth(i).substring(0, 3)

const nearestHalf = (num: number) => {
  return Math.round(num / 50) * 50
}

const WeightGraph = ({ currentWeight, targetWeight }: any) => {
  const weightDiff = Math.min(36, (currentWeight - targetWeight) / 2)

  const weights = {
    start: Math.round(currentWeight),
    middle: Math.round(currentWeight - weightDiff),
    end: Math.round(targetWeight),
  }

  const DataLabel = (props: any) => {
    const x = props.scale.x(props.x)
    const y = props.scale.y(props.y)
    return <VictoryLabel {...props} x={x} y={y} />
  }

  const goalMonths = Math.round((currentWeight - targetWeight) / 8)
  const goalDate = new Date()
  goalDate.setMonth(goalDate.getMonth() + goalMonths)

  const halfwayMonths = Math.round(goalMonths / 2)

  return (
    <>
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id="lgrad" x1="0%" y1="93%" x2="100%" y2="7%">
            <stop
              offset="0%"
              style={{ stopColor: 'rgb(255,255,255)', stopOpacity: 1 }}
            />
            <stop
              offset="60%"
              style={{ stopColor: 'rgb(253,236,157)', stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#lgrad)" />
      </svg>
      <VictoryChart
        // adding the material theme provided with Victory
        theme={VictoryTheme.material}
        height={200}
        padding={{
          left: 35,
          right: 35,
          top: 25,
          bottom: 25,
        }}
        animate={{
          duration: 1000,
          onLoad: { duration: 50 },
        }}
      >
        <VictoryAxis
          style={{
            axis: {
              fill: '#aaa',
              stroke: '#aaa',
              strokeDasharray: 0,
              strokeWidth: 2,
            },
            grid: {
              fill: '#e9e9e9',
              stroke: '#e9e9e9',
              strokeDasharray: 0,
              strokeWidth: 2,
              pointerEvents: 'painted',
            },
            ticks: {
              fill: '#aaa',
              size: 5,
              stroke: '#aaa',
              strokeWidth: 1,
            },
            tickLabels: { fontSize: 9, padding: 2, textTransform: 'uppercase' },
          }}
          tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          tickFormat={[
            getMonth(0),
            '',
            '',
            '',
            '',
            getMonth(halfwayMonths),
            '',
            '',
            '',
            '',
            goalDate.toLocaleString('default', {
              month: 'short',
              year: 'numeric',
            }),
          ]}
        />
        <VictoryAxis
          dependentAxis
          tickValues={[0, 1, 2, 3, 4, 5]}
          tickFormat={[
            nearestHalf(weights.end - 26),
            '',
            nearestHalf(weights.middle),
            '',
            nearestHalf(weights.start - 26),
            '',
          ]}
          style={{
            axis: {
              fill: '#aaa',
              stroke: '#aaa',
              strokeDasharray: 0,
              strokeWidth: 2,
            },
            grid: {
              fill: '#e9e9e9',
              stroke: ({ tick }: any) =>
                tick > 0.5 ? '#e9e9e9' : 'transparent',
              strokeDasharray: 0,
              strokeWidth: 1,
              pointerEvents: 'painted',
            },
            ticks: {
              fill: '#aaa',
              size: 5,
              stroke: '#aaa',
              strokeWidth: 1,
            },
            tickLabels: { fontSize: 9, padding: 2 },
          }}
        />
        <VictoryArea
          style={{
            data: { fill: 'url(#lgrad)', stroke: '#000000', strokeWidth: 2 },
          }}
          interpolation={'basis'}
          data={[
            { x: 0, y: 4.5 },
            { x: 1.5, y: 4.2 },
            { x: 2.5, y: 3.5 },
            { x: 3, y: 3 },
            { x: 5, y: 1.4 },
            { x: 7, y: 1.2 },
            { x: 9, y: 1 },
            { x: 10, y: 1 },
          ]}
        />
        <VictoryScatter
          style={{
            data: {
              fill: '#FFD101',
              stroke: '#555',
              strokeWidth: 2,
            },
            labels: {
              fontSize: 11,
            },
          }}
          size={5}
          data={[
            { x: 0, y: 4.5, label: [`${weights.start} lbs`] },
            { x: 5, y: 1.6, label: '' },
            { x: 10, y: 1, label: [`${weights.end} lbs`] },
          ]}
          labels={({ datum }: any) => datum.x}
          labelComponent={
            <VictoryLabel
              dy={-17}
              style={[
                {
                  fontSize: 11,
                  lineHeight: 1.2,
                  fontWeight: '600',
                },
              ]}
              backgroundPadding={{
                top: 5,
                bottom: 3,
                left: 7,
                right: 7,
              }}
              backgroundStyle={{
                fill: '#fff',
                opacity: 1,
                stroke: '#aaa',
                strokeWidth: 1,
              }}
              textAnchor="middle"
            />
          }
        />
        <DataLabel
          x={5}
          y={2}
          dy={-19}
          style={[
            {
              fontSize: 11,
              lineHeight: 1.2,
              fontStyle: 'italic',
              textTransform: 'uppercase',
              letterSpacing: '0.1em',
            },
            {
              fontSize: 15,
              lineHeight: 1.2,
              fontWeight: '600',
            },
          ]}
          backgroundPadding={{
            top: 3,
            bottom: 4,
            left: 10,
            right: 10,
          }}
          backgroundStyle={{
            fill: '#fff',
            opacity: 1,
            stroke: '#aaa',
            strokeWidth: 1,
          }}
          textAnchor={'middle'}
          text={[`By ${getMonth(halfwayMonths)}: `, `${weights.middle} lbs`]}
        />
      </VictoryChart>
    </>
  )
}

export default WeightGraph
