import { configureStore, EnhancedStore, AnyAction } from '@reduxjs/toolkit'

import quizReducer from './quiz'
import userReducer from './user'
import orderReducer from './order'
import screenReducer from './screen'
import stripeReducer from './stripe'
import { experimentReducer } from './experiment'
import eligiblityReducer from './eligiblity'
import { combineReducers } from 'redux'
import { loadState, saveState } from './localStorage'
import throttle from 'lodash/throttle'

const appReducer = combineReducers({
  quiz: quizReducer,
  user: userReducer,
  order: orderReducer,
  screen: screenReducer,
  eligiblity: eligiblityReducer,
  stripe: stripeReducer,
  experiment: experimentReducer,
})

const reducer = (state: RootState, action: AnyAction) => {
  return appReducer(state, action)
}

let store: EnhancedStore
const isClient = typeof window !== 'undefined'

const persistedState = loadState()

if (isClient) {
  store = configureStore({
    reducer,
    preloadedState: persistedState?.state,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  })
} else {
  store = configureStore({
    reducer,
    preloadedState: persistedState?.state,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  })
}

store.subscribe(
  throttle(() => {
    saveState({ state: store.getState() })
  }, 1000),
)

export default store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
