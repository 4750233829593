import store from '../redux/store'
import { selectUid } from '../redux/user'
import { AdvancedMatching } from '@bettercart/react-facebook-pixel'

export const getEventId = (eventName: string) => {
  let today

  if (eventName === 'Purchase' || eventName === 'Lead') {
    today = +new Date().toLocaleDateString().replaceAll('/', '')
  } else {
    today = Math.floor(+new Date() / 1000)
  }

  const UID = selectUid(store.getState())
  return `${eventName}-${today}-${UID}`
}

type EventProps = {
  eventName: string
  eventID?: string
  options?: any
  advancedMatchingOptions?: Partial<AdvancedMatching>
}
export const trackEvent = ({
  eventName,
  eventID = getEventId(eventName),
  options = {},
  advancedMatchingOptions,
}: EventProps) => {
  if (!process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID) {
    console.log('Could not load Facebook Pixel ID')
    return
  }

  if (typeof window === 'undefined') {
    return
  }

  try {
    import('@bettercart/react-facebook-pixel')
      .then(x => x.default)
      .then(ReactPixel => {
        if (advancedMatchingOptions) {
          // Properties used for advanced matching (email, first name, phone) need to be passed using fbq('init') event.
          // See: https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters#pixel-comparison
          // https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching/
          ReactPixel.init(
            process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID as string,
            advancedMatchingOptions as AdvancedMatching,
          )
        }
        ReactPixel.track(eventName, options, { eventID })
        console.log('Sent event', eventName, options || {}, { eventID })
      })
  } catch (error) {
    // Try again if it fails
    console.log('FB Pixel Error: ', error)
    console.log('Trying again, event: ', eventName)

    import('@bettercart/react-facebook-pixel')
      .then(x => x.default)
      .then(ReactPixel => {
        // Reinitialize ReactPixel and try again
        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID as string)
        ReactPixel.track(eventName, options, { eventID })
        console.log('Sent event', eventName, options || {}, { eventID })
      })
  }
}
