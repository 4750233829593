import React from 'react'

import HabitLateNightSVG from '../public/icons/habits-late-night.svg'
import HabitsNoneSVG from '../public/icons/habits-none.svg'
import HabitsSaltySVG from '../public/icons/habits-salty.svg'
import HabitsSleepSVG from '../public/icons/habits-sleep.svg'
import HabitsSodaSVG from '../public/icons/habits-sodas.svg'
import HabitsSweetsSVG from '../public/icons/habits-sweets.svg'

const iconTypes = {
  'habits-late-night': HabitLateNightSVG,
  'habits-none': HabitsNoneSVG,
  'habits-salty': HabitsSaltySVG,
  'habits-sleep': HabitsSleepSVG,
  'habits-soda': HabitsSodaSVG,
  'habits-sweets': HabitsSweetsSVG,
}

const IconComponent = ({ name, ...props }) => {
  const Icon = iconTypes[name]
  return <Icon {...props} />
}

export default IconComponent
