import { MapSelectOptions } from '@components/SelectOption'
import { SingleChoiceQuizStep, StepComponentProps } from '@types'
import React from 'react'

type Props = StepComponentProps<SingleChoiceQuizStep, string>

export default function SingleChoice(props: Props): React.ReactElement<Props> {
  const { value, step, onChange } = props

  const handleInputChange = (value: string) => {
    handleNext(value)
  }

  const handleNext = (newValue: string) => {
    onChange(step.id, newValue)
  }

  const options = step.options.map(option => ({
    value: option.value,
    content: option.name,
    selected: value == option.value,
    onClick: handleInputChange,
  }))

  return (
    <div className="flex flex-wrap min-w-full max-w-2xl items-center justify-center gap-2.5">
      {MapSelectOptions({
        width: 'w-full',
        options,
      })}
    </div>
  )
}
