import { Experiment } from '@types'
import { questions } from '@data/questions/base'
import Results from 'pages/results'
import QuizPage from 'pages/quiz'
import plans, { defaultPlan } from '@data/plans/base'
import dictionary from './dictionary'

const survey: Experiment = {
  id: 'survey',
  name: 'Measured - Modern Weight Loss',
  description:
    'Measured uses modern medicine and personalized care plans to help you lose weight and keep it off.',
  questions,
  resultsPage: Results,
  questionsPage: QuizPage,
  flowOrder: ['questionsPage', 'resultsPage'],
  funnel: 'FOX-HOME',
  default: true,
  oneTimePurchase: true,
  plans: { defaultPlan, ...plans },
  showPlanSelector: false,
  dictionary: dictionary,
}

export default survey
