import { Experiment, ExperimentPage, ExperimentPageFlow } from '@types'

export const getNextFlowIndex = (
  currentPage: ExperimentPage,
  experiment: Experiment | undefined,
) => {
  const currentIndex = getCurrentFlowIndex(currentPage, experiment)
  if (experiment && currentIndex !== -1) {
    const nextIndex = currentIndex + 1
    if (nextIndex < experiment.flowOrder.length) {
      return nextIndex
    }
  }
  return 0
}

export const getCurrentFlowIndex = (
  currentPage: ExperimentPage,
  experiment: Experiment | undefined,
) => {
  const currentFlow = ExperimentPageFlow[currentPage]
  return currentFlow && experiment
    ? experiment.flowOrder.indexOf(currentFlow)
    : -1
}

export const getExperimentIdRoute = (subdomain: string) => {
  const isStaging = /[^.\s]+-staging/s.test(subdomain)
  if (isStaging) {
    return subdomain.split('-')[0] || subdomain
  }
  return subdomain
}
