/* eslint-disable @next/next/no-img-element */
import { StepComponentProps, ContentQuizStep } from '@types'
import Actions from '@components/quiz/actions'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'

type ContentProps = StepComponentProps<ContentQuizStep, undefined>

const ContentReviews: React.FC<ContentProps> = (props: ContentProps) => {
  return (
    <div className="max-w-xl mx-auto">
      <Swiper
        modules={[Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={swiper => console.log(swiper)}
      >
        <SwiperSlide>
          <div className="review-content">
            <div className="quote">
              “For the first time in years I am losing weight and feel great.
              I&apos;ve been able to control my eating habits better than love
              watching the number go down!!”
            </div>
            <div className="user-info">
              <img src="/testimonial.jpeg" alt="Annabel S" />
              <span>Annabel S.</span>
              <img
                src="/testimonial-four-stars.svg"
                alt="Testimonial four stars"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="review-content">
            <div className="quote">
              “This is the first time I&apos;ve taken any kind of medication for
              weight loss. I&apos;ve been on it for 6 mos now and it&apos;s been
              great.”
            </div>
            <div className="user-info">
              <img src="/testimonial2.jpg" alt="Marissa R" />
              <span>Marissa R.</span>
              <img
                src="/testimonial-five-stars.svg"
                alt="Testimonial five stars"
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="review-content">
            <div className="quote">
              “I started Measured, I&apos;m down roughly 16lbs. I&apos;ve been
              sleeping better at night and I can actually run a little now!”
            </div>
            <div className="user-info">
              <img src="/testimonial3.jpg" alt="Michael P" />
              <span>Michael P.</span>
              <img
                src="/testimonial-four-stars.svg"
                alt="Testimonial four stars"
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <Actions
        nextEnabled={true}
        buttonText="Next"
        previousStep={props.previousStep}
        nextStep={props.nextStep}
        isLastStep={props.isLastStep}
      />
    </div>
  )
}

export default ContentReviews
