interface StepLabelProps {
  label: string
}

const StepHeading: React.FC<StepLabelProps> = ({ label }) => {
  return (
    <h1 dangerouslySetInnerHTML={{ __html: label }} className="quiz-heading" />
  )
}

export default StepHeading
