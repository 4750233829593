import ContinueButton from '@components/ContinueButton'
import React from 'react'

interface ActionsProps {
  nextStep?: number | null
  previousStep?: number
  nextEnabled: boolean
  isLastStep: boolean
  buttonText?: string
  onNext?: () => void
}

const Actions: React.FC<ActionsProps> = ({
  onNext,
  isLastStep,
  nextEnabled,
  buttonText = 'Next',
  nextStep,
}) => {
  const NextButton = (
    <ContinueButton text={buttonText} onClick={onNext} enabled={nextEnabled} />
  )

  const FinishButton = (
    <ContinueButton
      text="Finish"
      showArrow={false}
      onClick={onNext}
      enabled={nextEnabled}
    />
  )

  return (
    <div className="action-container">
      {nextStep && NextButton}
      {isLastStep && FinishButton}
    </div>
  )
}

export default Actions
