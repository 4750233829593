import { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentStep, selectProgressPercent } from 'redux/quiz/steps'

interface ProgressProps {
  showProgress?: boolean
}
const Progress: FunctionComponent<ProgressProps> = ({ showProgress }) => {
  const currentStep = useSelector(selectCurrentStep)
  const progressPercent = useSelector(selectProgressPercent)
  const [progress, setProgress] = useState(
    currentStep === 1 ? 10 : progressPercent,
  )

  useEffect(() => {
    if (currentStep === 1) {
      setProgress(10)
    } else {
      setProgress(progressPercent)
    }
  }, [currentStep, progressPercent])

  return !showProgress ? null : (
    <div className="progress-bar w-full mx-auto">
      <div
        className="rounded-full"
        style={{ width: `${progress || 10}%` }}
      ></div>
    </div>
  )
}
export default Progress
