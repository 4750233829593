import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

const GoogleAnalytics = ({ setLoadedScript }: any) => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
    }

    TagManager.initialize(tagManagerArgs)
    setLoadedScript('googleAnalytics')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export const trackGAEvent = ({ eventName, ...rest }: any) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (typeof window !== 'undefined' && !!window.gtag) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.gtag('event', eventName, {
      ...rest,
    })
    console.log('GA event tracked', eventName, rest)
  }
}

export default GoogleAnalytics
