import { CheckCircleIcon } from '@heroicons/react/24/outline'

interface ICheckMarkProps {
  className?: string
  selected?: boolean
  size?: number
}

const CheckMark = ({
  className,
  selected = false,
  size = 5,
}: ICheckMarkProps) => {
  const classNames = `${selected ? 'text-gray-600' : 'text-gray-400'} ${
    className ? className : ''
  }`
  return (
    <div>
      {selected ? (
        <CheckCircleIcon
          className={[`w-${size} h-${size} scale-125`, classNames].join(' ')}
        />
      ) : (
        <div
          className={[
            `rounded-full border-2 w-${size} h-${size}`,
            classNames,
          ].join(' ')}
        />
      )}
    </div>
  )
}

export default CheckMark
