import '../styles/globals.css'
import type { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import Layout from '../layouts/Layout'
import store from '../redux/store'
import { Provider } from 'react-redux'
// import EligibilityGuard from '@components/EligiblityGuard'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import Tracking from '@components/Tracking'
import { QueryParamProvider } from 'use-query-params'
import { NextAdapter } from 'next-query-params'
import OverrideTrack from '@components/OverrideTrack'
import { ExperimentRoute } from '@types'
import ExperimentProvider from 'contexts/ExperimentProvider'
import ErrorBoundary from '@components/ErrorBoundary'
import CouponProvider from '../contexts/CouponProvider'
import { setPromoCode } from 'redux/order'
import { trackGAEvent } from '@components/Scripts/GoogleAnalytics'

// const setBodyClass = (className: string) => {
//   const body = window.document.querySelector('body')
//   if (typeof window !== 'undefined' && body !== null) {
//     body.className = className
//   }
// }
function MyApp({
  Component,
  pageProps,
  experimentRouteData,
}: AppProps & ExperimentRoute) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: any) => {
      trackGAEvent({
        eventName: 'page_view',
        page_path: url,
      })
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    // set promo code based on query param
    const urlParams = new URLSearchParams(window.location.search)
    const promoCode = urlParams.get('promo') || 'HALFOFF'
    if (promoCode) {
      store.dispatch(
        setPromoCode({
          code: promoCode,
          offer:
            promoCode === 'HALFOFF'
              ? '50% off first 3 months'
              : '$45 off for first 3 months',
          description:
            promoCode === 'HALFOFF'
              ? '50% off first 3 months'
              : '$45 off for first 3 months',
          amount: 0,
          oneTime: true,
        }),
      )
    }

    // // Take asPath and clean it up into class names
    // const pathClass = asPath
    //   .split('/')
    //   .filter(s => s !== '')
    //   .join(' ')

    // // Set the body class
    // setBodyClass(pathClass)
  }, [router.asPath])

  return (
    <ErrorBoundary>
      <QueryParamProvider adapter={NextAdapter}>
        <Provider store={store}>
          <Head>
            <title>Measured - Modern Weight Loss</title>
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width"
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png?original"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png?original"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png?original"
            />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#FEFBF3" />
            <meta
              name="description"
              content="Measured uses modern medicine and personalized care plans to help you lose weight and keep it off."
            />
            <meta
              name="keywords"
              content="measured health,measured weight loss,lose weight,medical weight loss,glp-1,metformin,keto,low carb,prescription weight loss,semaglutide,wegovy,monjouro"
            />
          </Head>

          <CouponProvider query={experimentRouteData.query}>
            <ExperimentProvider experimentRoute={{ experimentRouteData }}>
              <Tracking>
                <OverrideTrack>
                  <Layout {...pageProps}>
                    <Component {...pageProps} />
                  </Layout>
                </OverrideTrack>
              </Tracking>
            </ExperimentProvider>
          </CouponProvider>
        </Provider>
      </QueryParamProvider>
    </ErrorBoundary>
  )
}

MyApp.getInitialProps = ({ ctx }: AppContext) => {
  const { req, query } = ctx
  let host = null
  if (req) {
    host = req.headers.host
  }
  return { experimentRouteData: { host, query } }
}

export default MyApp
