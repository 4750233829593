import { QuizInsurance, QuizSteps, QuizStepType, StepId } from '@types'

export const questions: QuizSteps[] = [
  {
    id: StepId.CONTENT_INTRO,
    type: QuizStepType.CONTENT_INTRO,
    content_type: 'html',
    label: '',
    hint: '',
  },
  {
    id: StepId.NAME,
    type: QuizStepType.TEXT,
    content_type: 'html',
    label: 'What’s your first name?',
    hint: "Let's get to know each other better.",
  },
  {
    id: StepId.GENDER,
    type: QuizStepType.SINGLE_CHOICE,
    label:
      '<em class="font-medium mr-2">Hi [name]! 👋</em> What biological sex were you assigned at birth?',
    hint: 'Measured clinicians need this information to order lab tests and prescribe certain medications. When you join, we will update your gender preferences.',
    options: [
      {
        name: 'Male',
        value: 'male',
      },
      {
        name: 'Female',
        value: 'female',
      },
    ],
  },
  {
    id: StepId.BIRTHDAY,
    type: QuizStepType.BIRTHDAY,
    label: "What's your date of birth?",
    _comment:
      'Should have a content slide after this that either explains how we use this data or sets up the following questions.',
  },
  {
    id: StepId.ZIPCODE,
    type: QuizStepType.ZIPCODE,
    label: 'What is your ZIP code?',
  },
  {
    id: StepId.HEIGHT_WEIGHT,
    type: QuizStepType.HEIGHT_WEIGHT,
    label: 'What is your height and weight?',
    showHeight: true,
    showWeight: true,
    weightId: StepId.CURRENT_WEIGHT,
    whyDoWeAsk: {
      description:
        'We use this to calculate your Body Mass Index (BMI), which is a factor that helps determine your weight loss plan.',
    },
  },
  {
    id: StepId.WEIGHT_LOSS,
    type: QuizStepType.SLIDER,
    label:
      'How much weight would<br class="hidden md:block" /> you like to lose?',
    min: 0,
    maxSelector: 'selectMaxWeightLoss',
  },
  {
    id: StepId.CONTENT_PERSONALIZED,
    type: QuizStepType.CONTENT,
    content_type: 'html',
    image: '/trifecta.png',
    label: 'Your journey to weight management starts here',
    hint:
      "<div class='callout-text mt-4'>To build a truly personalized treatment plan, your Measured Care Team will consider your biology, lifestyle, and medical history.</div>" +
      "<div class='callout-text mt-4'>We'll use a combination of diet, medicine, and health coaching to help you sustainably lose weight once and for all.</div>",
  },
  {
    id: StepId.LAST_IDEAL,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'When were you last at your ideal weight?',
    options: [
      {
        name: 'Less than 1 year ago',
        value: 'less_than_one_year',
      },
      {
        name: '1-2 years ago',
        value: 'one_to_two_years',
      },
      {
        name: 'More than 3 years ago',
        value: 'more_than_three_years',
      },
      {
        name: 'Never',
        value: 'never',
      },
    ],
  },
  {
    id: StepId.CONTENT_PERSONALIZED,
    type: QuizStepType.CONTENT,
    content_type: 'html',
    label: 'How Measured Works',
    content:
      '<div class="step-ladder"><div class="step"><div><span>1</span></div><div><strong>Online consultation & lab work</strong><p class="quiz-paragraph left">Answer questions about your medical history, lifestyle, and weight goals and submit recent lab work.</p></div></div>' +
      '<div class="step"><div><span>2</span></div><div><strong>Personalized treatment plan</strong><p class="quiz-paragraph left">Your clinician will work with you to develop a personalized treatment plan which can include prescription medications, a diet guide, and coaching.</p></div></div>' +
      '<div class="step"><div><span>3</span></div><div><strong>Ongoing support</strong><p class="quiz-paragraph left">With unlimited messaging, you get continued support from your Measured Care Team on your weight management journey.</p></div></div></div>',
  },
  {
    id: StepId.ACTIVITY_LEVEL,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'How physically active are you?',
    hint: 'Select an option from below.',
    options: [
      {
        name: 'Not very active',
        value: 'low',
      },
      {
        name: 'Exercise 1-2 times per week',
        value: 'medium',
      },
      {
        name: 'Exercise 3-5 times per week',
        value: 'high',
      },
      {
        name: 'Exercise 6+ times per week',
        value: 'very_high',
      },
    ],
  },
  {
    id: StepId.CONTENT_PERSONALIZED,
    type: QuizStepType.CONTENT_REVIEWS,
    content_type: 'html',
    label: 'Lose the weight for good.',
    hint: "We've helped thousands of people just like you lose weight and keep it off.",
    content:
      '<div class="review-content"><div class="quote">“For the first time in years I am losing weight and feel great. I\'ve been able to control my eating habits better than before. I love watching the number go down!!”</div><div class="user-info"><img src="/testimonial.jpeg" alt="Ann Pettit"><span>Ann Pettit</span><img src="/testimonial-five-stars.svg" alt="Testimonial five stars"></div></div>',
  },
  {
    id: StepId.WHY_MEASURED,
    type: QuizStepType.SINGLE_CHOICE,
    label: 'What part of the Measured program is most appealing to you?',
    hint: 'Select an option from below.',
    options: [
      {
        name: 'Unlimited messaging with an obesity-trained clinician',
        value: 'messaging_with_clinician',
      },
      {
        name: 'A dedicated health coach who can provide guidance and accountability',
        value: 'health_coach',
      },
      {
        name: "A personalized treatment plan that's specific to my biology",
        value: 'personalized_treatment_plan',
      },
      {
        name: 'Interest in improving my metabolic and physical health',
        value: 'improve_metabolic_health',
      },
      {
        name: 'Help establishing healthier food & exercise habits',
        value: 'healthier_habits',
      },
      {
        name: 'Desire to lose the weight once and for all',
        value: 'lose_weight',
      },
      {
        name: 'Other',
        value: 'other',
        showOther: true,
      },
    ],
  },
  {
    id: StepId.PREGNANT,
    type: QuizStepType.SINGLE_CHOICE,
    genderRestriction: ['female'],
    label: 'Are you currently pregnant or breastfeeding?',
    options: [
      {
        name: 'Yes',
        value: 'yes',
      },
      {
        name: 'No',
        value: 'no',
      },
    ],
  },
  {
    id: StepId.CONDITIONS,
    type: QuizStepType.MULTIPLE_CHOICE,
    label: 'Which of the following apply to you?',
    hint: 'Please select all that apply.',
    compact: true,
    options: [
      {
        name: 'Active drug or alcohol abuse',
        value: 'drug-use',
      },
      {
        name: 'Active hepatitis or liver disease',
        value: 'hepatitis',
      },
      {
        name: 'Use of insulin to control blood sugars',
        value: 'insulin',
      },
      {
        name: 'Daily use of steroid medications (such as prednisone)',
        value: 'steroid',
      },
      {
        name: 'Cancer treatment within the past 3 years',
        value: 'cancer',
      },
      {
        name: 'Heart attack, stroke, or any heart conditions that limit your daily activities within the last six months',
        value: 'heart',
      },
      {
        name: 'History of eating disorder (such as anorexia or bulimia nervosa)',
        value: 'eating-disorder',
      },
      {
        name: 'Serious mental health conditions (such as schizophrenia or bipolar disorder)',
        value: 'mental',
      },
      {
        name: 'Bariatric surgery (gastric sleeve, gastric bypass, SADI-S, or duodenal switch) in the last 6 months',
        value: 'weight-loss-surgery',
      },
      {
        name: 'Moderate or severe kidney disease or kidney transplant',
        value: 'kidney',
      },
      {
        name: 'None of these',
        value: 'none',
      },
    ],
  },
  {
    id: StepId.HEALTH_CONDITIONS,
    type: QuizStepType.MULTIPLE_CHOICE,
    hint: '(you can pick more than one)',
    compact: true,
    label: 'Do you have any of the below health conditions?',
    options: [
      {
        name: 'Diabetes',
        value: 'diabetes',
      },
      {
        name: 'Heart Disease',
        value: 'heart_disease',
      },
      {
        name: 'High Blood Pressure',
        value: 'high_blood_pressure',
      },
      {
        name: 'High Cholesterol',
        value: 'high_cholesterol',
      },
      {
        name: 'Sleep Apnea',
        value: 'sleep_apnea',
      },
      {
        name: 'None',
        value: 'none',
      },
    ],
  },
  {
    id: StepId.INSURANCE_PROVIDER,
    type: QuizStepType.MULTIPLE_CHOICE,
    label: 'Which forms of health insurance do you have?',
    hint: "Please select all plans you are currently enrolled in, including your secondary health insurance. <small class='block italic'><span class='font-semibold'>NOTE:</span> Measured is not able to treat individuals with Medicare, Medicaid, or Tricare at this time.</small>",
    options: [
      {
        name: 'Commercial or Employer',
        description: '(Aetna, Anthem, BCBS, UnitedHealth, etc.)',
        value: QuizInsurance.COMMERCIAL,
      },
      {
        name: 'Medicare, Medicaid, or Tricare',
        value: QuizInsurance.MEDICARE,
      },
      {
        name: 'Kaiser',
        value: QuizInsurance.KAISER,
      },
      {
        name: "I don't have insurance",
        value: QuizInsurance.NONE,
      },
    ],
  },
  {
    id: StepId.CONTENT_VERIFY,
    type: QuizStepType.CONTENT_VERIFY,
    label: 'Review your answers',
    hint: 'If you need to make any changes, click the back arrow above.',
    content: '',
  },
  // {
  //   id: StepId.CONTENT_SET_POINT,
  //   type: QuizStepType.CONTENT,
  //   content_type: 'html',
  //   image: '/measured-guarantee.svg',
  //   label: 'Measured works directly with your insurance so you don’t have to.',
  //   content:
  //     'Our Prior Authorization team will work directly with your insurance to help you gain affordable access to medications like GLP-1s in as little as 5 business days. This means you have more time to focus on what’s important – your health.',
  // },
]

export const conditionsQuestion: QuizSteps = {
  id: StepId.CONDITIONS,
  type: QuizStepType.MULTIPLE_CHOICE,
  label: 'Which of the following apply to you?',
  hint: 'This information is important to know if we can help you<br />(please select all that apply)',
  compact: true,
  options: [
    {
      name: 'Pregnant or currently breastfeeding',
      value: 'pregnancy',
    },
    {
      name: 'Cancer treatment within the past 3 years',
      value: 'cancer',
    },
    {
      name: 'Active drug or alcohol abuse',
      value: 'drug-use',
    },
    {
      name: 'History of eating disorder: anorexia or bulimia nervosa',
      value: 'eating-disorder',
    },
    {
      name: 'Bariatric surgery (gastric sleeve, gastric bypass, SADI-S, or duodenal switch) in the last 6 months',
      value: 'weight-loss-surgery',
    },
    {
      name: 'Serious mental health conditions',
      value: 'mental',
    },
    {
      name: 'Moderate or severe kidney disease or kidney transplant',
      value: 'kidney',
    },
    {
      name: 'Active hepatitis or liver disease',
      value: 'hepatitis',
    },
    {
      name: 'Daily use of steroid medications (e.g. prednisone)',
      value: 'steroid',
    },
    {
      name: 'Insulin to control blood sugars',
      value: 'insulin',
    },
    {
      name: 'Heart attack, stroke, or any heart conditions that limit your daily activities within the last six months',
      value: 'heart',
    },
    {
      name: 'None of these',
      value: 'none',
    },
  ],
}
