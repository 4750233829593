export default function calculateAge(
  year: number,
  month: number,
  day: number,
): number {
  const today = new Date()
  const birthDate = new Date(year, month, day)
  let age = today.getFullYear() - year
  const m = today.getMonth() - month
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}
