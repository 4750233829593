interface StepHintProps {
  hint: string
}

const StepHint: React.FC<StepHintProps> = ({ hint }) => {
  return (
    <p
      dangerouslySetInnerHTML={{ __html: hint }}
      className="quiz-paragraph font-medium"
    />
  )
}

export default StepHint
