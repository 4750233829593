import { ExperimentRoute } from '@types'
import { getExperimentIdRoute } from 'experiments/utils'
import { ReactNode } from 'react'
import { setExperimentId } from 'redux/experiment'
import { useAppDispatch } from 'redux/hooks'

interface IExperimentProviderProps {
  experimentRoute: ExperimentRoute
  children: ReactNode
}

const QUERY_PARAM = 'exp_id'

const ExperimentProvider = ({
  experimentRoute,
  children,
}: IExperimentProviderProps) => {
  const dispatch = useAppDispatch()
  let expId: string | undefined | null = null

  const { host, query } = experimentRoute.experimentRouteData
  if (query[QUERY_PARAM]) {
    expId = query[QUERY_PARAM]?.toString()
  } else if (host) {
    const [subdomain] = host.split('.')
    expId = getExperimentIdRoute(subdomain)
  }
  if (expId) {
    dispatch(setExperimentId(expId))
  }
  return <div>{children}</div>
}

export default ExperimentProvider
