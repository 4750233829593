import { Component } from 'react'
import * as Sentry from '@sentry/react'

interface ErrorBoundaryProps {
  hasError: boolean
}

class ErrorBoundary extends Component<any, ErrorBoundaryProps> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: any, info: { componentStack: any }) {
    try {
      Sentry.captureException(error)
      Sentry.captureMessage(info.componentStack)
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <h1 className="quiz-heading error-boundary">Something went wrong.</h1>
      )
    }

    return (
      <Sentry.ErrorBoundary
        fallback={
          <h1 className="quiz-heading sentry-error-boundary">
            Something went wrong.
          </h1>
        }
      >
        {this.props.children}
      </Sentry.ErrorBoundary>
    )
  }
}

export default ErrorBoundary
