import { useState } from 'react'
import Image from 'next/image'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'
import { selectCurrentStep, selectPreviousStep } from 'redux/quiz/steps'
import { useRouter } from 'next/router'
import Progress from './Progress'
import Modal from './Modal'

const Header = () => {
  const previousStep = useSelector(selectPreviousStep)
  const currentStep = useSelector(selectCurrentStep)
  const [showModal, setShowModal] = useState(false)
  const router = useRouter()

  const shouldDisplayBackButton =
    !router.asPath.includes('ineligible-insurance') &&
    !router.pathname.includes('/order/') &&
    currentStep !== 1 &&
    !router.pathname.includes('/confirmation')

  const toggleHelpModal = () => {
    setShowModal(!showModal)
  }

  const goToPrevStep = () => {
    if (
      router.asPath.includes('ineligible-insurance') ||
      router.asPath.includes('your-plan') ||
      router.asPath.includes('checkout')
    ) {
      window.history.back()
    } else {
      if (previousStep) {
        router.push({
          pathname: '/',
          query: {
            ...router.query,
            step: previousStep,
          },
        })
      } else {
        window.history.back()
      }
    }
  }

  return (
    <>
      <Modal
        showModal={showModal}
        toggleModal={toggleHelpModal}
        modalClass="modal-help"
        modalTitle="Need Help?"
      >
        <div className="mx-auto">
          <div className="p-4">
            <p className="font-light mt-2 mb-5 text-lg">
              We&apos;re here to answer any questions you may have.
            </p>
            <h5 className="text-lg md:text-base">Access Your Plan:</h5>
            <p className="font-light mb-3">
              You can get your plan anytime by{' '}
              <a
                href="https://app.trymeasured.com/"
                className="text-brand-primary-4-darker underline"
              >
                clicking here
              </a>
              .
            </p>
            <h5 className="text-lg md:text-base">Other Questions:</h5>
            <p className="font-light mb-10">
              Feel free to send us an email at{' '}
              <a
                href="mailto:support@trymeasured.com"
                className="text-brand-primary-4-darker underline"
              >
                support@trymeasured.com
              </a>
              .
            </p>
            <button
              onClick={toggleHelpModal}
              className="bg-brand-primary-4-darker hover:bg-brand-primary-4-lighter text-white rounded px-x py-2 block font-semibold w-full"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <div className="max-w-2xl w-full mx-auto mt-6 mb-12">
        <div className="flex items-center justify-between mb-6 px-5 md:px-0">
          <div className="w-1/4">
            <ArrowLeftIcon
              className={`h-7 w-7 relative text-secondary1 back-nav cursor-pointer ${
                shouldDisplayBackButton ? 'block' : 'hidden'
              }`}
              onClick={() => goToPrevStep()}
            />
          </div>
          <div className={'w-1/2 flex justify-center items-center'}>
            <div className="relative">
              <Image
                src={'/m-wordmark.svg'}
                alt="Measured Weight Loss"
                width={152}
                height={24}
                priority={true}
                style={{
                  height: 'auto',
                }}
              />
            </div>
          </div>
          <div className="w-1/4 text-right"></div>
        </div>
        <div className="relative w-full max-w-2xl mx-auto">
          <Progress showProgress={true} />
        </div>
      </div>
    </>
  )
}

export default Header
