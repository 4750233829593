import SingleChoice from '@components/quiz/questions/single-choice'
import TextInput from '@components/quiz/questions/text-input'
import NumberInput from '@components/quiz/questions/number-input'
import MultipleChoice from '@components/quiz/questions/multiple-choice'
import HeightWeight from '@components/quiz/questions/height-weight'
import Content from '@components/quiz/questions/content'
import ContentIntro from '@components/quiz/questions/content-intro'
import ContentReviews from '@components/quiz/questions/content-reviews'
import ContentVerify from '@components/quiz/questions/content-verify'
import Birthday from '@components/quiz/questions/birthday'
import Select from '@components/quiz/questions/select'

import {
  MultipleChoiceQuizStep,
  QuizSteps,
  QuizStepType,
  StepComponentProps,
} from '@types'
import Slider from './slider'
import ZipCode from './ZipCode'
import LabResults from './LabResults'

type ComponentType = React.FC<StepComponentProps<any, any>>

export const components: Record<QuizStepType, ComponentType> = {
  [QuizStepType.BIRTHDAY]: Birthday,
  [QuizStepType.CONTENT]: Content,
  [QuizStepType.CONTENT_INTRO]: ContentIntro,
  [QuizStepType.CONTENT_REVIEWS]: ContentReviews,
  [QuizStepType.CONTENT_VERIFY]: ContentVerify,
  [QuizStepType.HEIGHT_WEIGHT]: HeightWeight,
  [QuizStepType.MULTIPLE_CHOICE]: MultipleChoice,
  [QuizStepType.NUMBER]: NumberInput,
  [QuizStepType.SINGLE_CHOICE]: SingleChoice,
  [QuizStepType.SELECT]: Select,
  [QuizStepType.TEXT]: TextInput,
  [QuizStepType.ZIPCODE]: ZipCode,
  [QuizStepType.SLIDER]: Slider,
  [QuizStepType.LAB_RESULTS]: LabResults,
  [QuizStepType.INSURANCE]: MultipleChoice,
}

export const getQuestionDefaultValue = (step: QuizSteps): any => {
  switch (step.type) {
    case QuizStepType.MULTIPLE_CHOICE:
    case QuizStepType.INSURANCE:
      // eslint-disable-next-line no-case-declarations
      const multipleChoiceStep = step as MultipleChoiceQuizStep
      if (multipleChoiceStep.negation) {
        return multipleChoiceStep.options
          .map(o => o.value)
          .filter(value => value === 'none')
      } else {
        return []
      }
    case QuizStepType.HEIGHT_WEIGHT:
      return {
        height: null,
        weight: null,
      }
    case QuizStepType.SINGLE_CHOICE:
    case QuizStepType.SELECT:
      return ''
    case QuizStepType.SLIDER:
      return 10
    default:
      return null
  }
}
