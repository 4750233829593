import { useState, useMemo, useEffect } from 'react'
import { NumberQuizStep, StepComponentProps } from '../../../@types'
import Actions from '@components/quiz/actions'

type Props = StepComponentProps<NumberQuizStep, number>

const isValid = (value: number, step: NumberQuizStep): boolean => {
  if (!value) {
    return false
  }

  if (step.max && value > step.max) {
    return false
  }

  if (step.min && value < step.min) {
    return false
  }

  return true
}

export default function NumberInput(props: Props): React.ReactElement<Props> {
  const { value, step, onChange } = props
  const [result, setResult] = useState(value)
  const validResult = useMemo(() => isValid(result, step), [result, step])

  useEffect(() => {
    setResult(value)
  }, [value])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResult(parseInt(event.target.value))
  }

  const nextHandler = () => {
    onChange(step.id, result)
  }

  return (
    <>
      <div className="mt-1 relative rounded-md">
        <input
          onChange={handleInputChange}
          required={true}
          type="number"
          value={result || ''}
          name={step.id}
          id={step.id}
          min={step.min}
          max={step.max}
          pattern={step.pattern}
          autoFocus={true}
          className="form-control border rounded shadow-md p-3 text-xl w-30"
          placeholder={step.placeholder}
        />
        {step.units && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span
              className="text-lg ml-2 text-gray-700 fade-in text-left"
              id={`${step.id}-units`}
            >
              {step.units}
            </span>
          </div>
        )}
      </div>
      <Actions
        onNext={nextHandler}
        nextEnabled={validResult}
        isLastStep={props.isLastStep}
        previousStep={props.previousStep}
        nextStep={props.nextStep}
      />
    </>
  )
}
