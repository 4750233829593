import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'

type ModalProps = {
  showModal: boolean
  modalClass?: string
  modalTitle?: string
  toggleModal?: () => void
  children: React.ReactNode
  closeIconColor?: string
}
const Modal: React.FC<ModalProps> = ({
  showModal = false,
  modalClass = 'modal-default',
  modalTitle = '',
  toggleModal,
  children,
  closeIconColor = 'text-brand-text',
}) => {
  return (
    <div
      className={`modal ${modalClass} ${showModal ? 'flex fade-in' : 'hidden'}`}
    >
      <div className="modal-wrapper">
        <div className="modal-title">
          <h4>{modalTitle}</h4>
          <XMarkIcon
            className={`cursor-pointer fill-current ${closeIconColor}`}
            width="30"
            height="30"
            onClick={toggleModal}
          />
        </div>
        <div className="modal-content">
          <div>{children}</div>
        </div>
        <div className="modal-overlay" onClick={toggleModal} />
      </div>
    </div>
  )
}

export default Modal
