import { useEffect, useMemo, useState } from 'react'

export const SelectOption = ({
  content,
  width,
  height,
  value,
  onClick,
  selected = false,
  dense,
}: {
  content: string
  dense?: boolean
  height?: string
  onClick?: (value: string, selected: boolean) => void
  selected?: boolean
  value: string
  width?: string
}) => {
  const [isSelected, setIsSelected] = useState(selected)

  useEffect(() => {
    setIsSelected(selected)
  }, [selected])

  const clickSelect = (ev: any) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (ev.target.id === 'other-field-input') {
      return
    }
    setIsSelected(!isSelected)
    onClick && onClick(value, !isSelected)
  }

  const wrapClasses = [
    'flex text-center items-stretch justify-center',
    width,
    height,
  ]
    .join(' ')
    .trim()

  const itemClasses = [
    isSelected ? 'selected' : '',
    dense ? 'dense' : '',
    'select-option-item',
  ]
    .join(' ')
    .trim()

  return (
    <div className={wrapClasses}>
      <div
        className={itemClasses}
        id={`select-${value}`}
        onClick={clickSelect}
        data-value={value}
      >
        {content}
      </div>
    </div>
  )
}

export const MapSelectOptions = ({
  width = 'w-1/2 max-w-md',
  height = '',
  options,
}: any) => {
  const dense = useMemo(() => {
    return options.length >= 6
  }, [options])

  return options.map((option: any, i: number) => (
    <SelectOption
      key={i}
      width={width}
      height={height}
      dense={dense}
      {...option}
    />
  ))
}
